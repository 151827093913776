<template>
  <v-app class="grey lighten-4">
    <section class="py-5 h-100 d-flex justify-content-center main-div-login">
      <!-- <NavBar /> -->
      <v-card
        elevation="2"
        outlined
        :class="webbreakdown() ? 'w-100' : 'w-75'"
        class="m-auto login-subdiv"
      >
        <v-card-text class="p-4 main-content-div login-main-div">
          <v-row class="w-100 m-0">
            <v-col cols="12" sm="12" lg="6" class="border-right image-div">
              <h2 class="text-primary-color">{{ $t("qrate") }}</h2>
              <br />
              <img
                alt="QRate"
                class="logo_img_lg w-100 h-75"
                src="../../assets/mobile_payments.png"
                style="max-height: 75% !important"
              />
            </v-col>

            <v-col cols="12" sm="12" lg="6" class="pb-2">
              <v-form v-model="valid" ref="form">
                <div class="w-100 m-auto p-5 main-text">
                  <h2 class="text-center text-primary-color font-weight-bold">
                    {{ $t("userRegistraion") }}
                  </h2>
                  <br />

                  <v-alert
                    v-if="alertMsg.status"
                    :timeout="alertMsg.timeout"
                    :color="alertMsg.color"
                    :type="alertMsg.type"
                    dismissible
                  >
                    {{ alertMsg.text }}
                  </v-alert>
                  <v-select
                    v-model="user_type"
                    :items="user_types"
                    disabled
                    :label="$t('user_type')"
                    :placeholder="$t('user_type_placeholder')"
                    item-text="txt"
                    item-value="val"
                    :rules="user_type_rules"
                  >
                    <template v-slot:prepend-inner class="pt-0">
                      <v-icon class="me-4 pb-2 font-size">{{
                        user_type === "IND"
                          ? "mdi-account-tie"
                          : "mdi-briefcase-account"
                      }}</v-icon>
                    </template></v-select
                  >
                  <v-text-field
                    v-if="login_type === 'mobile'"
                    type="number"
                    hide-spin-buttons
                    id="sendAmount"
                    v-model="mobile_number"
                    :hint="'+60 xx xxx xxxx'"
                    :disabled="login_type === 'mobile' ? true : false"
                    color="primary"
                    :placeholder="$t('mobile_number_placeholder')"
                    required
                    prefix="+60"
                    class="custome-text-field"
                    :label="$t('mobile_number')"
                  >
                    <template v-slot:prepend-inner class="pt-0">
                      <country-flag country="my" class="mb-0 me-2" />
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-if="login_type === 'email'"
                    v-model="user_email"
                    :label="$t('emailID')"
                    :placeholder="$t('email_placeholder')"
                    :disabled="login_type === 'email' ? true : false"
                    clearable
                    append-icon="mdi-account-outline"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :append-icon="
                      isPwdVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                    "
                    @click:append="() => (isPwdVisible = !isPwdVisible)"
                    :type="isPwdVisible ? 'text' : 'password'"
                    @input="passwordcheck(password)"
                    :label="$t('password')"
                    :placeholder="$t('enter_new_password')"
                    clearable
                    :rules="newpasswordRules"
                    @focusin="showpass = true"
                    @focusout="showpass = false"
                    class="mb-2"
                  >
                  </v-text-field>
                  <v-card class="z-index triangle" v-if="showpass">
                    <v-row no-gutters>
                      <v-col cols="12" md="4" class="d-flex">
                        <div>
                          <v-list nav dense class="d-flex">
                            <div>
                              <v-list-item class="text-left pa-0">
                                <v-list-item-icon class="mr-0">
                                  <v-icon
                                    :color="
                                      has_lowercase && has_uppercase
                                        ? 'green'
                                        : ''
                                    "
                                    >{{
                                      has_lowercase && has_uppercase
                                        ? "mdi-checkbox-multiple-marked-circle"
                                        : "mdi-radiobox-marked"
                                    }}</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content class="ml-2">
                                  <v-list-item-title>
                                    {{ $t("lower_case_text") }}
                                    {{ $t("atleast_text") }}
                                    {{ passwordRegaxObj.capital_alphabet }}
                                    {{ $t("upper_case_text") }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item class="text-left pa-0">
                                <v-list-item-icon class="mr-0">
                                  <v-icon :color="has_number ? 'green' : ''">{{
                                    has_number
                                      ? "mdi-checkbox-multiple-marked-circle"
                                      : "mdi-radiobox-marked"
                                  }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="ml-2">
                                  <v-list-item-title>
                                    {{ $t("atleast_text") }}
                                    {{ passwordRegaxObj.numeric }}
                                    {{
                                      passwordRegaxObj.numeric > 1
                                        ? "Numbers"
                                        : "Number"
                                    }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item class="text-left pa-0">
                                <v-list-item-icon class="mr-0">
                                  <v-icon :color="has_special ? 'green' : ''">{{
                                    has_special
                                      ? "mdi-checkbox-multiple-marked-circle"
                                      : "mdi-radiobox-marked"
                                  }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="ml-2">
                                  <v-list-item-title>
                                    {{ $t("atleast_text") }}
                                    {{ passwordRegaxObj.special_character }}
                                    {{ $t("special_charecter") }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item class="text-left pa-0">
                                <v-list-item-icon class="mr-0">
                                  <v-icon
                                    :color="
                                      password.length >=
                                      (passwordRegaxObj &&
                                      passwordRegaxObj.length
                                        ? passwordRegaxObj.length
                                        : 5)
                                        ? 'green'
                                        : ''
                                    "
                                    >{{
                                      password.length >=
                                      (passwordRegaxObj &&
                                      passwordRegaxObj.length
                                        ? passwordRegaxObj.length
                                        : 5)
                                        ? "mdi-checkbox-multiple-marked-circle"
                                        : "mdi-radiobox-marked"
                                    }}</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content class="ml-2">
                                  <v-list-item-title>
                                    {{ $t("atleast_text") }}
                                    {{
                                      passwordRegaxObj &&
                                      passwordRegaxObj.length
                                        ? passwordRegaxObj.length
                                        : 6
                                    }}
                                    {{ $t("characters") }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                          </v-list>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-text-field
                    v-model="cpassword"
                    :append-icon="
                      isCPwdVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                    "
                    @click:append="() => (isCPwdVisible = !isCPwdVisible)"
                    :type="isCPwdVisible ? 'text' : 'password'"
                    :label="$t('cpassword')"
                    :placeholder="$t('re_enter_newpassword')"
                    clearable
                    :rules="confirmpasswordRules"
                    @input="ConfirmPassword(cpassword)"
                    @keydown="enterKeyPressed"
                  ></v-text-field>

                  <div class="d-flex justify-content-left align-items-center">
                    <v-checkbox
                      v-model="termsAndCondition"
                      color="orange darken-3"
                    ></v-checkbox>
                    <h6 class="mt-2">
                      {{ $t("i_have_read") }}
                      <a @click="loadpdf()" class="primary--text tcanchor">{{
                        $t("terms_and_conditions_and_privacy")
                      }}</a>
                    </h6>
                  </div>

                  <v-btn
                    block
                    rounded
                    color="primary"
                    @click="onSubmit"
                    class="btn-primary mt-3 text-capitalize"
                    :disabled="!termsAndCondition"
                  >
                    <div class="button-loader" v-if="loader">
                      <span class="spinner-border spinner-border-sm"></span>
                    </div>
                    <div v-else>{{ $t("btnText") }}</div>
                  </v-btn>

                  <div
                    class="text-dark mt-4 d-flex justify-content-center align-items-baseline"
                  >
                    <span>{{ $t("back_to") }}</span>
                    <v-btn
                      class="text-capitalize text-primary-color p-2"
                      color="primary"
                      plain
                      @click="$router.push('/login')"
                    >
                      {{ $t("loginPage") }}?
                    </v-btn>
                  </div>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="istermsdialod"
        :width="webbreakdown() ? '100%' : '60%'"
        transition="dialog-bottom-transition"
        class="ma-0"
      >
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">
            {{ $t("terms_and_conditions_and_privacy") }}
            <v-spacer></v-spacer>
            <v-btn color="" fab small @click="istermsdialod = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <!-- loading an html file which is converted from pdf in iframe -->
          <v-card-text :class="webbreakdown() ? 'pa-1 m-0' : 'pa-4 m-0'">
            <iframe src="Terms.html" width="100%" height="490px"></iframe>
          </v-card-text>
        </v-card>
      </v-dialog>
    </section>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { webbreakdown } from "../../utils/breakpoints";
import {
  generateRegex,
  generateSpecialCharsRegex,
  generateDigitsRegex,
  generateCapitalAlphabetsRegex,
} from "../../utils/passwordCheckers/regexCreator";
import { returnuserTypes } from "../../utils/constDropdowns/userType";
import { i18n as $ } from "../../locale/index";
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
export default {
  name: "Registration",
  mixins: [alerts],
  data() {
    return {
      webbreakdown,
      generateRegex,
      generateSpecialCharsRegex,
      generateCapitalAlphabetsRegex,
      generateDigitsRegex,
      isPwdVisible: false,
      isCPwdVisible: false,
      emailId: null,
      user_email: "",
      password: "",
      cpassword: "",
      passwordRegaxObj: {},
      user_type: sessionStorage.getItem("selected_user_type"),
      user_types: returnuserTypes(),
      mobile_number: "",
      termsAndCondition: false,
      istermsdialod: false,
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      loader: false,
      dismissCountDown: 0,
      countDownAlert: 0,
      showError: false,
      showpass: false,
      login_type: "",
      password_strength: 0,
      has_number: false,
      has_lowercase: false,

      has_uppercase: false,
      has_special: false,

      newpasswordRules: [(value) => !!value || $.t("password_required")],
      confirmpasswordRules: [(value) => !!value || $.t("conf_Password_req")],
      mobileRules: [
        (v) => !!v || $.t("mobile_required"),
        (v) => /^(([0-9]*){20})$/.test(v) || $.t("mobile_required"),

        (v) => (v && v.length >= 9) || $.t("min_mobile_number"),
        (v) => (v && v.length <= 12) || $.t("max_mobile_number"),
      ],
      user_type_rules: [(v) => !!v || $.t("usertype_req")],
    };
  },

  computed: {
    ...mapGetters("Authentication"),
  },
  mounted() {
    this.login_type = this.$route.query.login_type;
    if (this.login_type === "mobile") {
      this.mobile_number = this.$route.query.user_name;
    } else if (this.login_type === "email") {
      this.user_email = this.$route.query.user_name;
    }
    this.getpasswordFormat();
  },
  methods: {
    ConfirmPassword(val) {
      if (this.password != val) {
        this.confirmpasswordRules.push("Password is mismatched.");
      } else {
        this.confirmpasswordRules = [
          (value) => !!value || "Password is Required.",
        ];
      }
    },
    passwordcheck(val) {
      /* eslint-disable */
      console.log("pass :", val);
      const password = val;
      let strength = 0;
      const numeric_regax = this.generateDigitsRegex(
        this.passwordRegaxObj.numeric
      );

      this.has_number = numeric_regax.test(password);
      if ((this.has_number = numeric_regax.test(password))) {
        strength += 1;
      }
      const uppercase_regax = this.generateCapitalAlphabetsRegex(
        this.passwordRegaxObj.capital_alphabet
      );
      this.has_lowercase = /[a-z]{2}/.test(password);
      this.has_uppercase = uppercase_regax.test(password);
      if (this.has_lowercase && this.has_uppercase) {
        strength += 1;
      }
      const specialchar_regax = this.generateSpecialCharsRegex(
        this.passwordRegaxObj.special_character
      );
      this.has_special = specialchar_regax.test(password);
      if ((this.has_special = specialchar_regax.test(password))) {
        strength += 1;
      }
      if (password.length >= this.passwordRegaxObj.length) {
        strength += 1;
      }
      this.password_strength = strength;
      if (
        this.has_lowercase &&
        this.has_number &&
        this.has_uppercase &&
        this.has_special &&
        password.length >= this.passwordRegaxObj.length
      ) {
        this.newpasswordRules = [(value) => !!value || "Password is Required."];
      } else {
        this.newpasswordRules.push("Password strength is weak");
      }
    },
    removeblur() {
      this.showpass = false;
      console.log("input out focus");
    },
    showpasscheck() {
      this.showpass = true;
      console.log("input focus");
    },
    loadpdf() {
      this.istermsdialod = true;
    },
    enterKeyPressed(event) {
      if (event.keyCode == 13) this.onSubmit();
    },
    checkNumber(val) {
      if (val.substring(0, 2) === "60") {
        return val;
      } else {
        return "60" + val;
      }
    },
    async getpasswordFormat() {
      try {
        let responseData = await userservice.getPasswordformat();
        console.log("locatioln", responseData.data);
        if (responseData.data.status_code === 200) {
          console.log("locatioln==>", responseData.data.data[0]);
          this.passwordRegaxObj = responseData.data.data[0];
          console.log(this.generateRegex(responseData.data.data[0]));
        }
      } catch (e) {
        return e;
      }
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        if (this.password === this.cpassword) {
          this.alertMsg.status = false;
          const userDetails = {
            user_name:
              this.login_type === "mobile"
                ? this.checkNumber(this.mobile_number)
                : this.user_email,
            password: this.password,
            user_type: this.user_type,
          };

          try {
            this.loader = true;
            const responseData = await userservice.registerAccount_username(
              userDetails
            );
            if (responseData && responseData.data.success) {
              this.loader = false;
              this.showAlertSuccess(responseData.data.message);
              if (responseData.data.data[0].user_type) {
                sessionStorage.setItem(
                  "user_type",
                  responseData.data.data[0].user_type
                );
              }
              sessionStorage.setItem("selected_login_type", this.login_type);
              this.$setLocalStorage(
                "is_partner",
                responseData.data.data[0].is_partner
              );
              this.$setLocalStorage("logo", responseData.data.data[0].logo);
              sessionStorage.setItem(
                "selected_user_name",
                this.login_type === "mobile"
                  ? this.checkNumber(this.mobile_number)
                  : this.user_email
              );
              this.$setLocalStorage(
                "is_logo",
                responseData.data.data[0].is_logo
              );
              this.$setLocalStorage(
                "partner_type",
                responseData.data.data[0].partner_type
              );
              sessionStorage.setItem(
                "access_token",
                responseData.data.data[0].access_token
              );
              sessionStorage.setItem(
                "refresh_token",
                responseData.data.data[0].refresh_token
              );
              sessionStorage.setItem(
                "user_id",
                responseData.data.data[0].user_id
              );
              console.log("access", responseData.data.data[0].access_token);
              console.log("refersh", responseData.data.data[0].refresh_token);
              sessionStorage.setItem(
                "complete_registration",
                responseData.data.data[0].complete_registration
              );
              sessionStorage.setItem(
                "is_kyc",
                responseData.data.data[0].is_EKYC
              );

              this.$store.commit(
                "access_token",
                responseData.data.data[0].access_token
              );
              this.$store.commit(
                "complete_registration",
                responseData.data.data[0].complete_registration
              );

              if (responseData.data.data[0].user_type == "IND") {
                this.$router.push({ name: "CreateUserMain" });
              } else if (responseData.data.data[0].user_type == "BUS") {
                this.$router.push({ name: "BusUserInfo" });
              }
            } else {
              this.loader = false;
              this.isOTPEnable = false;
              this.showAlertError(responseData.data.message);
            }
          } catch (error) {
            this.loader = false;
            this.isOTPEnable = false;
            this.showAlertError(
              "something went wrong please try again sometime!"
            );
          }
        } else {
          this.showAlertError("Password is mismatched");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";

.custom-select {
  margin-top: 18px;
}
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 5px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    border: 1px solid red !important;
    color: $dimgrey;
    background: $white;
  }
}
::v-deep ul {
  list-style-type: none !important;
}
.card-title {
  color: $dimgrey;
}
.card-text {
  color: $lightslategrey;
}
label {
  color: $lightslategrey;
}
.logo_img {
  max-height: 70px;
}
.div-signup {
  color: $primary;
  cursor: pointer;
}
::v-deep .btn-light {
  border: 1px solid #d3d3d3 !important;
}
.card {
  border: none;
}
.floating-placeholder {
  color: red;
  font-size: 14px;
  text-align: -webkit-left;
}
.label-policy {
  display: flex;
}
.tcanchor:hover {
  border-bottom: 1px solid $primary;
}
.font-size {
  font-size: 28px;
}
.z-index {
  position: absolute;
  right: 0px;
  top: 310px;
  z-index: 1 !important;
}
</style>
